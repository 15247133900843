<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <validation-observer ref="editForm" #default="{ invalid }">
        <n-form-confirmation
          key="editForm"
          @submit="submit"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
            <template #awardFeatures>
              <div class="pt-2">
                <draggable
                  v-model="tempAwardFeatures"
                  :move="onMove"
                  :start="true"
                  :end="true"
                >
                  <transition-group type="transition" :name="'flip-list'">
                    <b-col
                      lg="12"
                      md="12"
                      class="p-0"
                      v-for="(feature, idx) in tempAwardFeatures"
                      :key="feature.id"
                    >
                      <b-row>
                        <b-col lg="12">
                          <label
                            >{{ $t("field.image") }}
                            <span class="text-muted">
                              - {{ $t("field.optional") }}
                            </span>
                          </label>
                          <n-async-single-image-uploader
                            :key="`image-upload-${idx}-${featureKey}`"
                            path="award-feature"
                            ph="240"
                            pw="240"
                            :disableUploadHistory="true"
                            :fullWidth="false"
                            :image="feature.image"
                            v-model="feature.imageId"
                            @change="onUploadChange($event, idx)"
                          />

                          <b-row>
                            <b-col lg="6">
                              <validation-provider
                                #default="{ errors }"
                                :vid="`${feature.nameEn}`"
                                :name="$t('field.nameEn')"
                                rules="required"
                              >
                                <label class="mt-2"
                                  >{{ $t("field.nameEn") }}
                                  <span class="text-muted">
                                    - {{ $t("field.optional") }}
                                  </span>
                                </label>
                                <b-form-input
                                  placeholder="English Name"
                                  autocomplete="off"
                                  v-model="feature.nameEn"
                                  :class="
                                    errors.length > 0 ? 'is-invalid' : null
                                  "
                                />
                              </validation-provider>
                            </b-col>
                            <b-col lg="6">
                              <validation-provider
                                #default="{ errors }"
                                :vid="`${feature.nameKm}`"
                                :name="$t('field.nameKm')"
                                rules="required"
                              >
                                <label class="mt-2"
                                  >{{ $t("field.nameKm") }}
                                  <span class="text-muted">
                                    - {{ $t("field.optional") }}
                                  </span>
                                </label>
                                <b-form-input
                                  placeholder="Khmer Name"
                                  autocomplete="off"
                                  v-model="feature.nameKm"
                                  :class="
                                    errors.length > 0 ? 'is-invalid' : null
                                  "
                                />
                              </validation-provider>
                            </b-col>
                            <b-col lg="6">
                              <validation-provider
                                #default="{ errors }"
                                :vid="`${feature.descriptionEn}-${idx}`"
                                :name="$t('field.descriptionEn')"
                                rules="required"
                              >
                                <label>{{ $t("field.descriptionEn") }}</label>
                                <ckeditor
                                  tag-name="textarea"
                                  v-model="feature.descriptionEn"
                                  :name="feature.descriptionEn"
                                  :config="feature.configEn"
                                  :class="errors.length > 0 ? 'ck-invalid' : null"
                                ></ckeditor>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col lg="6">
                              <validation-provider
                                #default="{ errors }"
                                :vid="`${feature.descriptionKm}-${idx}`"
                                :name="$t('field.descriptionKm')"
                                rules="required"
                              >
                                <label>{{ $t("field.descriptionKm") }}</label>
                                <ckeditor
                                  tag-name="textarea"
                                  v-model="feature.descriptionKm"
                                  :name="feature.descriptionKm"
                                  :config="feature.configKm"
                                  :class="errors.length > 0 ? 'ck-invalid' : null"
                                ></ckeditor>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col align-self="end" class="text-right">
                          <b-button
                            variant="outline-danger"
                            class="mt-1"
                            @click="removeFeature(idx)"
                          >
                            <feather-icon
                              icon="XIcon"
                              class="mr-1"
                            ></feather-icon>
                            <span>{{ $t("button.delete") }}</span>
                          </b-button>
                        </b-col>
                      </b-row>
                      <hr class="mb-2" />
                    </b-col>
                  </transition-group>
                </draggable>
                <b-col class="p-0">
                  <b-button
                    variant="primary"
                    class="mb-2"
                    @click="addMoreFeature"
                  >
                    <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
                    <span>{{ $t("button.addFeature") }}</span>
                  </b-button>
                </b-col>
              </div>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                :hide-create="true"
                v-if="$can('update', resource)"
              ></n-button-save-option>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";
import NAsyncSingleImageUploader from "@/components/NAsyncSingleImageUploader";
import draggable from "vuedraggable";
import ckeditorConfig from "@/libs/ckeditorconfig";
const AwardRepository = Repository.get("award");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BFormTextarea,
    draggable,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
    NAsyncSingleImageUploader,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      key: 1,
      featureKey: 1,
      featureIndex: 1,
      tempAwardFeatures: [],
      awardId:null,
      data: {
        bannerId: null,
        logoId: null,
        featureImageId: null,
        link: null,
        isEnable: true,

        titleEn: null,
        descriptionEn: "",
        metaTitleEn: null,
        metaDescriptionEn: null,
        overviewDescriptionEn: "",

        titleKm: null,
        descriptionKm: "",
        metaTitleKm: null,
        metaDescriptionKm: null,
        overviewDescriptionKm: "",

        awardContents: [],
        awardFeatures: [],
      },
      initData: {},
      loading: false,
      type: 2,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    onUploadChange(event, idx) {
      const index = this.tempAwardFeatures.findIndex((element, i) => {
        return idx == i;
      });
      if (index != -1) {
        this.tempAwardFeatures[index].image = event.fileUrl;
      }
    },
    show() {
      AwardRepository.show({})
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.initData = {
              ...data,
            };
            this.awardId = data.id;

            data.awardContents.forEach((content) => {
              if (content.locale == "en") {
                this.data.titleEn = content.title;
                this.data.descriptionEn = content.description;
                this.data.metaTitleEn = content.metaTitle;
                this.data.metaDescriptionEn = content.metaDescription;
                this.data.overviewDescriptionEn = content.overviewDescription;
              } else if (content.locale == "km") {
                this.data.titleKm = content.title;
                this.data.descriptionKm = content.description;
                this.data.metaTitleKm = content.metaTitle;
                this.data.metaDescriptionKm = content.metaDescription;
                this.data.overviewDescriptionKm = content.overviewDescription;
              }
            });

            data.awardFeatures.forEach((feature, index) => {
              this.featureIndex = index + 1;
              if (index % 2 == 0) {
                this.tempAwardFeatures.push({
                  id: index + 1,
                  imageId: feature.imageId,
                  image: feature.image,
                  nameEn: feature.name,
                  descriptionEn: feature.description,
                  nameKm: data.awardFeatures[index + 1].name,
                  descriptionKm: data.awardFeatures[index + 1].description,
                  configEn: { ...ckeditorConfig.editorConfig },
                  configKm: { ...ckeditorConfig.editorConfig },
                });
              }
            });
        
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit(type) {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          type = this.type;
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;
      this.data.awardContents = [];
      this.data.awardFeatures = [];

      this.data.awardContents.push(
        {
          locale: "en",
          title: this.data.titleEn,
          description: this.data.descriptionEn,
          metaTitle: this.data.metaTitleEn,
          metaDescription: this.data.metaDescriptionEn,
          overviewDescription: this.data.overviewDescriptionEn,
        },
        {
          locale: "km",
          title: this.data.titleKm,
          description: this.data.descriptionKm,
          metaTitle: this.data.metaTitleKm,
          metaDescription: this.data.metaDescriptionKm,
          overviewDescription: this.data.overviewDescriptionKm,
        }
      );

      this.tempAwardFeatures.forEach((feature, index) => {
        this.data.awardFeatures.push(
          {
            locale: "en",
            imageId: feature.imageId,
            sequenceOrder: index + 1,
            name: feature.nameEn,
            description: feature.descriptionEn,
            
          },
          {
            locale: "km",
            imageId: feature.imageId,
            sequenceOrder: index + 1,
            name: feature.nameKm,
            description: feature.descriptionKm,
          }
        );
      });
      if(this.awardId !== null){
        AwardRepository.update(this.awardId, this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.editForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
      }else{

        AwardRepository.create(this.data)
        .then((response) => {
          this.awardId = response.data.data.id;
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.createForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
      }


    },
    addMoreFeature() {
      this.featureIndex++;
      let feature = {
        id: this.featureIndex,
        imageId: null,
        image: "",
        nameEn: "",
        nameKm: "",
        descriptionEn: "",
        descriptionKm: "",
        configEn: { ...ckeditorConfig.editorConfig },
        configKm: { ...ckeditorConfig.editorConfig },
      };
      this.tempAwardFeatures.push(feature);
    },
    removeFeature(idx) {
      this.tempAwardFeatures.splice(idx, 1);
      this.featureKey++;
    },
    back() {
      this.$router.push({
        name: `update-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "award";
    const route = "award";

    return { fields, resource, route };
  },
};
</script>