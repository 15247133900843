var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('load-profile'),_c('b-card',[_c('validation-observer',{ref:"editForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"editForm",attrs:{"form":_vm.$refs.editForm,"disabled":invalid},on:{"submit":_vm.submit}},[_c('n-input',{attrs:{"fields":_vm.fields,"initValue":_vm.initData},scopedSlots:_vm._u([{key:"awardFeatures",fn:function(){return [_c('div',{staticClass:"pt-2"},[_c('draggable',{attrs:{"move":_vm.onMove,"start":true,"end":true},model:{value:(_vm.tempAwardFeatures),callback:function ($$v) {_vm.tempAwardFeatures=$$v},expression:"tempAwardFeatures"}},[_c('transition-group',{attrs:{"type":"transition","name":'flip-list'}},_vm._l((_vm.tempAwardFeatures),function(feature,idx){return _c('b-col',{key:feature.id,staticClass:"p-0",attrs:{"lg":"12","md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("field.image"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('n-async-single-image-uploader',{key:("image-upload-" + idx + "-" + _vm.featureKey),attrs:{"path":"award-feature","ph":"240","pw":"240","disableUploadHistory":true,"fullWidth":false,"image":feature.image},on:{"change":function($event){return _vm.onUploadChange($event, idx)}},model:{value:(feature.imageId),callback:function ($$v) {_vm.$set(feature, "imageId", $$v)},expression:"feature.imageId"}}),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":("" + (feature.nameEn)),"name":_vm.$t('field.nameEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("field.nameEn"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":"English Name","autocomplete":"off"},model:{value:(feature.nameEn),callback:function ($$v) {_vm.$set(feature, "nameEn", $$v)},expression:"feature.nameEn"}})]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":("" + (feature.nameKm)),"name":_vm.$t('field.nameKm'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("field.nameKm"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":"Khmer Name","autocomplete":"off"},model:{value:(feature.nameKm),callback:function ($$v) {_vm.$set(feature, "nameKm", $$v)},expression:"feature.nameKm"}})]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":((feature.descriptionEn) + "-" + idx),"name":_vm.$t('field.descriptionEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t("field.descriptionEn")))]),_c('ckeditor',{class:errors.length > 0 ? 'ck-invalid' : null,attrs:{"tag-name":"textarea","name":feature.descriptionEn,"config":feature.configEn},model:{value:(feature.descriptionEn),callback:function ($$v) {_vm.$set(feature, "descriptionEn", $$v)},expression:"feature.descriptionEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":((feature.descriptionKm) + "-" + idx),"name":_vm.$t('field.descriptionKm'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t("field.descriptionKm")))]),_c('ckeditor',{class:errors.length > 0 ? 'ck-invalid' : null,attrs:{"tag-name":"textarea","name":feature.descriptionKm,"config":feature.configKm},model:{value:(feature.descriptionKm),callback:function ($$v) {_vm.$set(feature, "descriptionKm", $$v)},expression:"feature.descriptionKm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"align-self":"end"}},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeFeature(idx)}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.delete")))])],1)],1)],1),_c('hr',{staticClass:"mb-2"})],1)}),1)],1),_c('b-col',{staticClass:"p-0"},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":_vm.addMoreFeature}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.addFeature")))])],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.$can('update', _vm.resource))?_c('n-button-save-option',{ref:"btnSubmit",attrs:{"loading":_vm.loading,"resource":_vm.resource,"route":_vm.route,"hide-create":true},on:{"submit":_vm.submit,"save":_vm.save}}):_vm._e()],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }