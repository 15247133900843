import ckeditorconfig from "@/libs/ckeditorconfig";
export default [
    // {
    //   label: "breadcrumb.about",
    //   icon: "GlobeIcon",
    //   type: "divider",
    // },
    {
      key: "bannerId",
      initKey: "banner",
      label: "field.banner",
      type: "async-single-image",
      path: "banner",
      pw: 2000,
      ph: 768,
      disableUploadHistory: true,
      cols: 12,
    },

    {
      key: "logoId",
      initKey: "logo",
      label: "field.logo",
      type: "async-single-image",
      path: "award",
      pw: 200,
      ph: 200,
      fullWidth: true,
      disableUploadHistory: true,
      compressMineType: "image/png",
      cols: 6,
  
    },

    {
      key: "featureImageId",
      initKey: "featureImage",
      label: "field.featureImage",
      type: "async-single-image",
      path: "award",
      pw: 1920,
      ph: 1079,
      fullWidth: true,
      disableUploadHistory: true,
      cols: 6,
  
    },
    {
      key: "link",
      label: "field.link",
      type: "text"
    },
    {
      label: "breadcrumb.englishAwardContent",
      icon: "FileTextIcon",
      type: "divider",
    },
    {
      key: "titleEn",
      label: "field.titleEn",
      rules: "required",
      type: "text",
      cols: 6,
    },
    {
      key: "descriptionEn",
      label: "field.descriptionEn",
      type: "ck-editor",
      rules: "required",
      config:{...ckeditorconfig.editorConfig},
      cols: 12
    },
  
    {
      key: "overviewDescriptionEn",
      label: "field.overviewDescriptionEn",
      type: "ck-editor",
      rules: "required",
      config:{...ckeditorconfig.editorConfig},
      cols: 12
    },
  
    {
      key: "metaTitleEn",
      label: "field.metaTitleEn",
      type: "text",
      cols: 6,
    },
    {
      key: "metaDescriptionEn",
      label: "field.metaDescriptionEn",
      type: "textarea",
      cols: 12
    },
  
  
    {
      label: "breadcrumb.khmerAwardContent",
      icon: "FileTextIcon",
      type: "divider",
    },
    {
      key: "titleKm",
      label: "field.titleKm",
      rules: "required",
      type: "text",
      cols: 6,
    },
    {
      key: "descriptionKm",
      label: "field.descriptionKm",
      type: "ck-editor",
      rules: "required",
      config:{...ckeditorconfig.editorConfig},
      cols: 12
    },
    {
      key: "overviewDescriptionKm",
      label: "field.overviewDescriptionKm",
      type: "ck-editor",
      rules: "required",
      config:{...ckeditorconfig.editorConfig},
      cols: 12
    },
    {
      key: "metaTitleKm",
      label: "field.metaTitleKm",
      type: "text",
      cols: 6,
    },
    {
      key: "metaDescriptionKm",
      label: "field.metaDescriptionKm",
      type: "textarea",
      cols: 12
    },
  
    {
      label: "breadcrumb.awardFeature",
      icon: "FileTextIcon",
      type: "divider",
    },
    {
      key:"awardFeatures",
      hideLabel:true,
      type: "slot",
      cols:12,
    },
    {
      key: "isEnable",
      label: "field.active",
      type: "checkbox",
      cols: "auto",
    },
  ];
  